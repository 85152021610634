import React from 'react';

import './Signup.css';

class Signup extends React.Component {
  render() {
    return null;
  }
}

export default Signup;
